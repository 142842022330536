<template>
    <div class="mx-1">
        <b-button @click="changeLange(locales[+activeIndex].locale)" class="btn-icon p-1 text-white" variant="flat-secondary" style="font-size: 12px">
            {{locales[+activeIndex].name}}
        </b-button>
    </div>
</template>
<style lang="scss">
.dropdown-language {
    .dropdown-menu {
        min-width: 77px;
    }
}
</style>
<script>
import store from '@/store'
import { getLang, setLang } from "@/libs/i18n/index.js";
import globalEvent from "@/libs/global-event" 

export default {
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale);
        }
    },
    data: () => ({
        activeIndex: 0
    }),
    setup() {
        const locales = [
            {
                locale: "en",
                name: "EN",
                dir: "ltr"
            },
            {
                locale: "ar",
                name: "AR",
                dir: "rtl"
            }
        ];
        return {
            locales
        };
    },
    methods: {
        changeLange(locale) {
            this.activeIndex = !this.activeIndex
            console.log(locale)
            setLang(locale)
            this.$i18n.locale = locale
            store.commit('appConfig/TOGGLE_RTL')
            globalEvent.changeLang();
        }
    },
    mounted() {
        this.changeLange(getLang())
        this.activeIndex = +!(this.currentLocale.locale == 'ar')
    }
};
</script>
